<template>
  <div>
    <!-- <div class="bottom-bg"></div> -->
    <div class="footer d-flex df-ai-center df-jc-center">
      <div class="bg">
        <ul class="d-flex df-ai-center">
          <li
            :class="active === idx ? 'active' : ''"
            @click="handleClick(idx, item)"
            v-for="(item, idx) in list"
            :key="idx"
          >
            <span> 
              {{ item.name }}
              </span>
          </li>
        </ul>
      </div>
    </div>
 
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";

// import Search from "./search.vue";
export default {
  components: {
    Swiper,
    SwiperSlide,
    // Search,
  },
  data() {
    return {
      downShow: false,
      securityShow: false,
      swiperOption: {
        slidesPerView: 9,
        spaceBetween: 0,
        initialSlide: 0,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      list: [
        {
          url: "/partybuilding",
          name: "智慧党建",
        },
        {
          url: "/energy",
          name: "智慧能耗",
        },
        {
          url: "/home",
          name: "园区总览",
        },
        {
          url: "/fire",
          name: "智慧管理",
        },
        {
          url: "/factory",
          name: "阳光工厂",
        },
      ],
      active: 0,
      act: "",
      path: "",
      searchList: [],
      selectList: [
        {
          label: "实时监控",
          value: 3,
          url: "/home?page=subject",
        },
        {
          label: "设备运营",
          value: 2,
          url: "/home?page=assist",
        },
      ],
      selectShow: true,
      isActive: -1,
      showBtn: false,
    };
  },
  created() {
    this.changePage();
     if (this.$route.fullPath == "/video") {
      this.isActive = 1;
      this.active = 3;
    } else if (this.$route.fullPath == "/device") {
      this.isActive = 0;
      this.active = 3;
    }else if (this.$route.fullPath == "/fire") {
      this.isActive = 2;
      this.active = 3;
    }
  },
  watch: {
    $route() {
      this.changePage();
    },
  },
  methods: { 
    changePage() {
      let path = this.$route.fullPath;
      this.path = path;
      this.active = this.list.findIndex((it) => it.url == path);
    },
    handleClick(idx, item) {
      // if (item.name == "智慧管理") {
      //   this.active = idx;
      //   if(this.$route.fullPath == "/home") {
      //     this.showBtn = true
      //   }
      // } else {
 
          this.active = idx;
          this.$router.push({ path: item.url });
        
      
      // }
    },
    prev() {
      this.$refs.mySwiper.$swiper.slidePrev();
    },
    next() {
      this.$refs.mySwiper.$swiper.slideNext();
    },
    handleChange(item, i) {
      this.act = i;
    },
    handleChangeSearch(item) {
      this.$emit("change", item);
    },
    handleSecurity(idx) {
      if (idx == 1) {
        this.$router.push({ path: "/video" });
      } else if (idx == 0) {
        this.$router.push({ path: "/device" });
      }else if (idx == 2) {
        this.$router.push({ path: "/fire" });
      }
      this.isActive = idx;
      this.active = 3;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  z-index: 100;
  bottom: 0.2rem;
  left: 0;
  right: 0;
  height: 1rem;
  font-size: 0.23rem;
  div.bg {
    width: 100%;
    height: 1.5rem;
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    background-image: url("../../assets/home/footer.png");
    background-size: 70% 100%;
    background-repeat: no-repeat;
    background-position: bottom -0.2rem center;
  }
  ul {
    position: relative;
    bottom: -0.4rem;
    li {
      cursor: pointer;
      position: relative;
      padding: 0 0.4rem;
      margin: auto 0.22rem;
      user-select: none;
      background: url("../../assets/home/txt.png") no-repeat;
      background-size: 100% 48%;
      background-position-y: 0.11rem;
      span {
        font-size: 0.25rem;
        font-family: "YouSheBiaoTiHei";
        color: #fff;
        font-size: 0.25rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #d6eff6;
        opacity: 0.6;
       
      }
      &:hover {
        .urlBox {
           display: flex;
        }
      }
    }
    .active {
      background: url("../../assets/home/txt-active.png") no-repeat;
      background-size: 120% 150%;
      background-position: -0.18rem -0.05rem;
      span {
        font-weight: bold;
        color: #ffffff;
        opacity: 1;
        font-size: .3rem;
        text-shadow: 0px 4px 8px rgba(6, 82, 96, 0.6);
      }
    }
  }
  .search-list {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    padding: 0 0.5rem;
    .gc-img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .img {
        width: 0.6rem;
        height: 0.8rem;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 0.1rem;
      }
      img {
        width: 0.6rem;
      }
      span {
        font-size: 0.15rem;
        color: #fff;
        white-space: nowrap;
      }
      &.act {
        .img {
          width: 0.8rem;
          height: 0.8rem;
          align-items: center;
          // background-image: url("../../assets/footer/icon_bg.png");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100% 100%;
        }
        img {
          width: 0.5rem;
        }
      }
    }
    .swiper-box {
      .swiper-prev {
        position: absolute;
        left: 0;
      }
      .swiper-next {
        position: absolute;
        right: 0;
      }
    }
    .clear {
      position: absolute;
      right: -0.5rem;
      top: 0.2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        font-size: 0.15rem;
        color: #fff;
        margin-top: 0.1rem;
      }
    }
  }
}
.urlBox {
  position: fixed;
  left: 6rem;
  top: 1rem;
  color: #fff;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  border:solid 1px RGBA(16, 122, 167, 1);
  div {
    width: 1.2rem;
    height: 0.5rem;
    line-height: 0.5rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    text-align: center;
    font-size: 0.17rem;
    cursor: pointer;
    user-select: none;
    border-right:solid 1px RGBA(16, 122, 167, 1);
    background-color: RGBA(3, 38, 68, .6);
  }
  .li1 {
    
  }
  .li2 {
    border-right-width: 0px;
  }
  .isactive {
    font-weight: 700;
    color: #00d8ff;
    background-image: url("../../assets/home/f_btn.png");
  }
}
.icons {
  position: absolute;
  bottom: 1%;
  right: 38%;
  color: #fff;
  font-size: 0.2rem;
}
</style>